.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.empty {
  padding: 0 var(--mobile-horizontal-padding);
  color: var(--color-carbone-base);
  font-size: var( --font-size-small);
  line-height: var(--line-height-default);
  font-weight: var(--font-weight-default);
}

.nextList {
  display: none;

  &.visibleNext {
    display: block;
    margin-bottom: 20px;
  }
}

.moreButton {
  display: flex;
  align-items: center;
  padding: 0 var(--mobile-horizontal-padding);
  border: 0;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  color: var(--color-blue-base);
  font-size: var( --font-size-small);
  line-height: var(--line-height-default);
  font-weight: var(--font-weight-default);
  margin-bottom: 20px;
}

.moreIcon {
  margin-right: 10px;
}
