.root {
  display: grid;
  grid-template-columns: repeat(4, minmax(220px, 1fr));
  grid-column-gap: 20px;

  @media (--mobile) {
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
    margin-bottom: 20px;
  }
}

.item {
  font-family: var(--manrope);
  background-color: var(--color-white);
  padding: 15px;
  box-shadow: 0 5px 30px -10px #315efb4d;
  border-radius: 5px;

  &:hover,
  &:focus {
    & .title {
      color: var(--color-blue-middle);
    }
  }

  &:active {
    & .title {
      color: var(--color-blue-middle);
    }
  }
}

.head {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 10px;
  align-items: center;
  margin-bottom: 10px;
}

.image {
  width: 25px;
  height: 25px;
  border-radius: 5px;
}

.title {
  color: var(--color-carbone-base);
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-extra-bold);
  line-height: var(--line-height-large);
  transition: color var(--transition-duration);

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
}

.text {
  overflow: hidden;
  display: block;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  max-height: 80px;
  text-overflow: ellipsis;
  color: var(--color-carbone-base);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-default);
  line-height: var(--line-height-default);
  transition: color var(--transition-duration);
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;

  @media (--mobile) {
    font-size: 15px;
  }

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
}

.h3 {
  margin-bottom: 15px;
  font-size: var(--font-size-large);
  line-height: var(--line-height-large);
  font-weight: var(--font-weight-extra-bold);

  @media (--mobile) {
    margin: 20px 0;
  }
}
