.titleWrapper {
  display: flex;
  align-items: center;
  padding-right: var(--mobile-horizontal-padding);

  & h1 {
    margin-bottom: 10px;
    padding: 0 var(--mobile-horizontal-padding);
    font-size: var(--font-size-extra-large) !important;
    line-height: var(--line-height-extra-large) !important;
  }
}

.editButton {
  max-width: 30px;
  margin-left: 5px;
}

.icons {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 0 var(--mobile-horizontal-padding);
}

.icon {
  width: 30px;
  margin-right: 10px;
  border-radius: 5px;
}

.commentsCount {
  margin-left: auto;
}

.description {
  padding: 0 var(--mobile-horizontal-padding);
  margin-bottom: 20px;
}

.dayNavigation {
  margin-bottom: 20px;
}

.recommendedChannels {
  padding: 0 var(--mobile-horizontal-padding);
}

.breadcrumbsFooter {
  padding: 0 var(--mobile-horizontal-padding);
}
