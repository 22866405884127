.filtersPopup {
  position: absolute;
  left: 0;
  top: 80px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 10px;
  background-color: var(--color-white);
  box-shadow: 0 10px 10px -10px rgba(49 94 251 / 0.4);
}

.checkbox {
  margin-bottom: 20px;
}

.filterButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  border-radius: 50%;
  color: var(--color-carbone-base);
  background-color: var(--color-alt-blue-2);
  transition: all 200ms;

  &:active,
  &.isOpen {
    color: var(--color-blue-dark);
    background-color: var(--color-alt-blue-4);
  }

  @media (prefers-reduced-motion) {
    transition: none;
  }
}
