.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.empty {
  padding: 0 var(--mobile-horizontal-padding);
  color: var(--color-carbone-base);
  font-size: var(--font-size-small);
  line-height: var(--line-height-default);
  font-weight: var(--font-weight-default);
}

.moreButton {
  display: flex;
  align-items: center;
  border: 0;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  color: var(--color-cloud-gray-dark);
  font-size: var(--font-size-default);
  line-height: var(--line-height-default);
  font-weight: var(--font-weight-semi-bold);
  padding-left: 10px;

  &:active {
    color: var(--color-blue-base);
  }

  &.openMoreList {
    svg {
      transform: rotate(90deg);
    }
  }
}

.moreButtonPrev {
  margin-bottom: 20px;
}

.moreButtonNext {
  margin-top: 20px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  margin-right: 10px;
}
