.root {
  display: flex;
  flex-direction: column;

  h1 {
    font-size: var(--font-size-extra-large) !important;
    line-height: var(--line-height-extra-large) !important;
    padding: 0 var(--mobile-horizontal-padding);
  }
}

.titleWrapper {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding-right: var(--mobile-horizontal-padding);
}

.newsTitle {
  margin-bottom: 20px;
  font-size: var(--font-size-large);
  line-height: var(--line-height-large);
  font-weight: var(--font-weight-extra-bold);
}

.geoButton {
  display: flex;
  margin-left: 5px;
  color: var(--color-blue-base);
  padding: 0;
  border: 0;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    margin-top: 5px;
    margin-left: 5px;
    border-radius: 50%;
    background-color: var(--color-alt-blue-2);
  }
}

.selectFilter {
  position: relative;
  display: flex;
  width: 100%;
  padding: 20px var(--mobile-horizontal-padding);
}

.authWidget {
  margin-top: -10px;
}

.select {
  flex-grow: 1;
  margin-right: 20px;
}

.channel {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 20px;
  }
}

.channelTitle {
  padding: 0 15px;
  margin-bottom: 20px;
}

.moreButton {
  width: calc(100% - 20px);
  margin: 0 auto;
}

.bottom {
  margin-top: 20px;
  padding: 0 10px;

  h3 {
    margin-bottom: 20px;
  }
}

.recommend {
  margin-bottom: 20px;
}

.breadcrumbsFooter {
  margin-top: 20px;
  margin-bottom: 20px;
}

.listingAd {
  margin-bottom: 20px;
}
