.root {
  display: flex;
  flex-direction: column;
}

.favorites {
  display: flex;
  flex-direction: column;

  & h1 {
    padding: 0 var(--mobile-horizontal-padding);
    margin-bottom: 20px;
    font-size: var(--font-size-extra-large) !important;
    line-height: var(--line-height-extra-large) !important;
  }
}

.card {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 0 var(--mobile-horizontal-padding);
}

.cardTitle {
  display: flex;
  margin-bottom: 15px;
}

.showTitle {
  margin-right: auto;
  color: var(--color-carbone-base);
  font-family: var(--manrope);
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-extra-bold);
  line-height: var(--line-height-large);
}

.cardChannel {
  margin-bottom: 15px;
}

.scheduleBlock {
  margin: 0 -10px;
}

.emptyShows {
  font-weight: var(--font-weight-default);
  font-size: var(--font-size-default);
  line-height: var(--line-height-default);
  margin-bottom: 20px;
}

.cover {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 195px;
  margin-bottom: 15px;
  background-color: var(--color-alt-blue-1);
  border-radius: 5px;
}

.img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.schedule {
  display: flex;
  flex-direction: column;

  h2 {
    padding: 0 var(--mobile-horizontal-padding);
    margin-bottom: 20px;
  }
}

.selectFilter {
  position: relative;
  display: flex;
  width: 100%;
  padding: 20px var(--mobile-horizontal-padding);
}

.select {
  width: 200px;
  flex-grow: 1;
  margin-right: 20px;
}

.channel {
  margin-bottom: 30px;
}

.channelTitle {
  padding: 0 15px;
  margin-bottom: 20px;
}

.moreButton {
  flex-grow: 1;
  margin: 0 10px 20px;
}

.breadcrumbsFooter {
  padding: 0 var(--mobile-horizontal-padding);
}

.blueCard {
  background-color: var(--color-alt-blue-1);
  padding: 20px 20px 30px;
  text-align: center;

  & h2 {
    margin-bottom: 5px;
  }

  & > button {
    margin-top: 20px;
    width: 125px;
  }

  margin-bottom: 20px;
}

.premieres {
  margin-bottom: 20px;
}

.listingAd {
  margin-bottom: 20px;
}
