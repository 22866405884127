.root {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 0 var(--mobile-horizontal-padding);
  margin-bottom: 20px;
  border-radius: 5px;

  &button {
    padding: 0;
    border: 0;
    background-color: transparent;
    box-shadow: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    display: flex;
    align-items: center;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.forShow {
    flex-direction: column;
    align-items: stretch;
  }

  &.sports {
    background-color: var(--color-green-1);
  }

  &.kids {
    background-color: var(--color-pink);
  }

  &.series {
    background-color: var(--color-alt-blue-1);
  }

  &.movies {
    background-color: var(--color-yellow-1);
  }
}

.timeStart {
  min-width: 40px;
  max-width: 40px;
  margin-right: 10px;
  font-family: var(--manrope);
  color: var(--color-cloud-gray-dark);
  font-size: var(--font-size-small);
  line-height: var(--line-height-default);
  font-weight: var(--font-weight-default);

  &.active {
    color: var(--color-carbone-base);
  }
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.title {
  font-family: var(--manrope);
  color: var(--color-carbone-base);
  font-weight: var(--font-weight-default);
  font-size: var(--font-size-default);
  line-height: var(--line-height-default);

  .root:active &,
  .root:focus & {
    color: var(--color-blue-base);
  }

  &.active {
    font-weight: var(--font-weight-bold);
  }
}

.live {
  display: inline-block;
  vertical-align: middle;
  width: 5px;
  height: 5px;
  flex-shrink: 0;
  margin-left: 5px;
  background-color: var(--color-red-base);
  border-radius: 50%;
}

.weekend {
  display: inline-block;
  color: var(--color-red-base);

  &::first-letter {
    text-transform: uppercase;
  }
}

.time {
  color: var(--color-cloud-gray-dark);
}

.dateStart {
  display: inline-block;
  white-space: nowrap;

  .forShow & {
    margin-bottom: 5px;
  }
}
