.root {
  background-color: var(--color-alt-blue-1);
  height: 50px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.scroll {
  display: flex;
  align-items: center;
  flex: 1;
  white-space: nowrap;
  overflow: scroll hidden;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.link {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  cursor: pointer;
  font-family: var(--manrope);
  color: var(--color-carbone-base);
  font-size: var(--font-size-default);
  font-weight: var(--font-weight-default);
  line-height: var(--line-height-default);
  background: transparent;
  transition: color var(--transition-duration);

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }

  &:hover,
  &:focus {
    color: var(--color-blue-middle);

    & .dayName {
      color: var(--color-blue-base);
    }
  }

  &.active {
    color: var(--color-blue-base);

    & .dayName {
      color: var(--color-blue-base);
    }
  }
}

.dayName {
  color: var(--color-cloud-gray-dark);
  transition: color var(--transition-duration);

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }

  &.holidayColor {
    color: var(--color-red-base);
  }
}
