.titleWrapper {
  display: flex;
  align-items: center;
  padding: 20px var(--mobile-horizontal-padding) 20px;
}

.moreButton {
  width: calc(100% - 20px);
  margin: 0 10px 20px;
}

.selectFilter {
  position: relative;
  display: flex;
  width: 100%;
  padding: 20px var(--mobile-horizontal-padding);
}

.select {
  flex-grow: 1;
  margin-right: 20px;
}

.channel {
  margin-bottom: 30px;
}

.channelTitle {
  padding: 0 15px;
  margin-bottom: 20px;
}

.breadcrumbsFooter {
  padding: 0 var(--mobile-horizontal-padding);
  margin-bottom: 20px;
}

.listingAd {
  margin-bottom: 20px;
}
